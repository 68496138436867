a,
a:visited {
  text-decoration: none !important;
}

/* .MuiTextField-root {
    width: 100%;
  } */
.wrapperClassName {
  /* border: 1px solid #e3e3e3; */
  padding: 4px;
  border-radius: 3px;
  background: #fff;
}
.editorClassName {
  color: #212121;
  caret-color: #212121;
  border: 1px solid #eeeeee;
  padding: 2px;
  border-radius: 2px;
}

.toolbarClassName {
  color: #212121;
}
.full-w {
  width: 100%;
}
.right {
  text-align: right;
  padding: 6px;
}
.remove-button-styles {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.primary_color {
  color: #00ab55;
}

.login_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}
.signup_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 30px;
}

.page-fab {
  position: fixed;
  bottom: 24px;
  right: 24px;
}
/* Search suggestions */
.search-suggestions {
  position: absolute;
  /* background-color: #fff;
  box-shadow: 0 0 3px #e3e3e3; */
  width: 100%;
  padding: 6px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  border-radius: 9px;
}
.search-suggestions .search-item {
  margin: 3px;
  cursor: pointer;
  transition: all 0.3s;
}
.search-suggestions .search-item::first-letter {
  text-transform: capitalize;
}
.hidden {
  opacity: 0;
}
.capitalize-first::first-letter {
  text-transform: capitalize;
}
.has-shadow {
  box-shadow: 0 0 3px #e3e3e3;
}
.p-2 {
  padding: 3px;
}
/* Search suggestions */
